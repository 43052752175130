import React, { useContext } from "react"
import { globalContext } from "../../../../contexts/GlobalContext"
import { numberWithCommas } from "../../../../functions"
import { CartItem } from "../../../../types"
import { CURRENCY_WON } from "../../../../values"
const styles = require("./style.module.scss")

interface CartItemProps {
  cartItem: CartItem
}

const CartItemInDelivery = ({ cartItem }: CartItemProps) => {
  const context = useContext(globalContext)

  const pcVersion = (
    <div className={"h-box " + styles["container"]}>
      <div className={"h-box " + styles["descriptionContainer"]}>
        <div className={"v-box " + styles["informationContainer"]}>
          <span className={styles["productNameText"]}>{cartItem && cartItem.product.name}</span>
          <span
            className={styles[cartItem && cartItem.product.eventPrice ? "eventText" : "priceText"]}
          >
            {cartItem && numberWithCommas(cartItem.product.eventPrice || cartItem.product.price)}
            {CURRENCY_WON}
          </span>
        </div>
      </div>
      <div className={styles["countBox"]}>{cartItem && cartItem.count}</div>
      <span
        className={
          styles[cartItem && cartItem.product.eventPrice ? "eventTotalText" : "priceTotalText"]
        }
      >
        {cartItem &&
          numberWithCommas(
            cartItem.count * (cartItem.product.eventPrice || cartItem.product.price)
          )}
        {CURRENCY_WON}
      </span>
    </div>
  )

  const mobileVersion = (
    <div className={"v-box " + styles["mobileContainer"]}>
      <div className={"h-box " + styles["descriptionContainer"]}>
        <div className={"v-box " + styles["informationContainer"]}>
          <span className={styles["productNameText"]}>{cartItem && cartItem.product.name}</span>
          <span
            className={styles[cartItem && cartItem.product.eventPrice ? "eventText" : "priceText"]}
          >
            {cartItem && numberWithCommas(cartItem.product.eventPrice || cartItem.product.price)}
            {CURRENCY_WON}
          </span>
          <div className="h-box-spaced" style={{ marginTop: 10 }}>
            <span className={styles["countBox"]}>{cartItem && cartItem.count}</span>
            <span
              className={
                styles[
                  cartItem && cartItem.product.eventPrice ? "eventTotalText" : "priceTotalText"
                ]
              }
            >
              {cartItem &&
                numberWithCommas(
                  cartItem.count * (cartItem.product.eventPrice || cartItem.product.price)
                )}
              {CURRENCY_WON}
            </span>
          </div>
        </div>
      </div>
    </div>
  )

  if (context?.isPc) return pcVersion
  else return mobileVersion
}

export default CartItemInDelivery
