import React, { createContext } from "react"
import { useMediaQuery } from "react-responsive"
import { MEDIA_QUERY } from "../../values"
import useCustomer from "./hook/useCustomer"

interface ContextValueType {
  customerHook: ReturnType<typeof useCustomer>
}

export const deliveryContext = createContext<ContextValueType | null>(null)

const DeliveryContextProvider = ({ children }: { children: React.ReactNode }) => {
  const isPc = useMediaQuery({
    query: MEDIA_QUERY,
  })

  const customerHook = useCustomer(isPc)

  const value: ContextValueType = {
    customerHook,
  }

  return <deliveryContext.Provider value={value}>{children}</deliveryContext.Provider>
}

export default DeliveryContextProvider
