import publicIp from "public-ip"
import React, { useEffect, useRef, useState } from "react"
import { ModelSortDirection } from "../../../API"
import { handleError, handleSuccess } from "../../../functions"
import {
  apiGetDelivery,
  apiGetDeliverysForCustomer,
  apiListDeliverys,
} from "../../../functions/api/delivery"
import { Delivery } from "../../../types"
import { DELIVERY_LOCAL_KEY } from "../../../values"

interface useCustomerProps {}

const useCustomer = () => {
  const [deliveries, setDeliveries] = useState<Delivery[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        /*
        const ip = await publicIp.v4()
        const response = await apiListCustomers({ ip: { eq: ip } })
        handleSuccess(response!)
        console.log(response)

        if (response && response.length !== 0) {
          const customer = response[0]
          const customerDeliveries = await apiGetDeliverysForCustomer(
            customer.id,
            undefined,
            ModelSortDirection.DESC
          )
          console.log(customerDeliveries)
          const data = customerDeliveries?.map(d => ({
            ...d,
            items: d.items.map(i => JSON.parse(i)),
          }))
          if (customerDeliveries) setDeliveries(data)
        }
        */

        //localStorage.removeItem(DELIVERY_LOCAL_KEY)

        const jsonString = localStorage.getItem(DELIVERY_LOCAL_KEY)
        if (jsonString) {
          let localDeliveryIds = JSON.parse(jsonString) as string[]
          let remoteDeliveries: Delivery[] = []

          for(let id of localDeliveryIds) {
            const delivery = await apiGetDelivery(id)
            if(delivery) {
              remoteDeliveries.push(delivery)
            } else {
              localDeliveryIds = localDeliveryIds.filter(localId => localId !== id)
            }
          }

          setDeliveries(remoteDeliveries)
          localStorage.setItem(
            DELIVERY_LOCAL_KEY,
            JSON.stringify(localDeliveryIds)
          )
        }
      } catch (error) {
        handleError(error)
      }
    })()
  }, [])

  return {
    deliveries,
  }
}

export default useCustomer
