import moment from "moment"
import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import { PaymentType } from "../../API"
import ArrowButton from "../../components/atom/ArrowButton"
import PageTitleText from "../../components/atom/PageTitleText"
import Layout from "../../components/organism/Layout"
import DeliveryContextProvider, { deliveryContext } from "../../contexts/DeliveryContext"
import { numberWithCommas } from "../../functions"
import { Delivery, DeliveryStatus } from "../../types"
import { CURRENCY_WON, DELIVERY_PAGE_TITLE, MEDIA_QUERY, PAGE } from "../../values"
import CartItemInDelivery from "./component/CartItem"
import DeliveryItem from "./component/DeliveryItem"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import { Typography } from "@material-ui/core"
import { css } from "@emotion/react"
const styles = require("./style.module.scss")

const DeliveryPage = () => {
  const [selectedDeliveryIndex, setSelectedDeliveryIndex] = useState<number>(0)

  function getSum(delivery?: Delivery) {
    let priceSum = 0
    let salePriceSum = 0

    if (delivery && delivery.items) {
      delivery.items.forEach(item => {
        priceSum += item.product.price * item.count
        salePriceSum +=
          (item.product.eventPrice ? item.product.price - item.product.eventPrice : 0) * item.count
      })
    }

    return {
      price: numberWithCommas(priceSum),
      sale: numberWithCommas(salePriceSum * -1),
      total: numberWithCommas(priceSum - salePriceSum),
    }
  }

  function isSelectedDeliveryCancelled(selectedDelivery?: Delivery) {
    if (selectedDelivery && selectedDelivery.status == DeliveryStatus.CANCELLED) return true
    else return false
  }

  function getDeliveryStatusText(delivery?: Delivery) {
    switch (delivery?.status) {
      case "ORDERED":
        return "주문 접수"
      case "OUT_FOR_DELIVERY":
        return "배송중"
      case "DELIVERED":
        return "배송완료"
      case "CANCELLED":
        return "취소됨"
      default:
        break
    }
  }

  function formatPaymentType(type: PaymentType | undefined) {
    switch (type) {
      case PaymentType.CASH:
        return "현금"
      case PaymentType.CREDIT:
        return "카드"
      default:
        return ""
    }
  }

  const orderedDeliveryListUI = (context: any) => (
    <>
      <div className={styles["desktopContainer"]}>
        {context?.customerHook.deliveries.length == 0 ? (
          <div className={styles["emptyDeliveryItem"]}>배달 내역 없음</div>
        ) : (
          context?.customerHook.deliveries.map((item, index) => (
            <DeliveryItem
              delivery={item}
              index={index}
              isSelected={index === selectedDeliveryIndex}
              onClick={() => {
                setSelectedDeliveryIndex(index)
              }}
            />
          ))
        )}
      </div>
      <div className={styles["mobileDeliveryListContainer"]} style={{ marginTop: 10 }}>
        {context?.customerHook.deliveries &&
          context?.customerHook.deliveries.map((item, index) => (
            <button
              className={"v-box " + styles["mobileDeliveryButton"]}
              onClick={() => setSelectedDeliveryIndex(index)}
              style={{ alignItems: "center", marginRight: 15 }}
            >
              <div
                className={
                  styles[`mobileDelivery${selectedDeliveryIndex == index ? "Selected" : ""}`]
                }
              >
                {index + 1}
              </div>
              <span
                className={
                  styles[
                    `mobileDeliveryDateText${selectedDeliveryIndex == index ? "Selected" : ""}`
                  ]
                }
              >
                {getDeliveryStatusText(item)}
              </span>
            </button>
          ))}
      </div>
    </>
  )

  const selectedDeliveryUI = (context: any) => {
    const status = context?.customerHook.deliveries[selectedDeliveryIndex]?.status

    const orderBasicDataContainer = css`
      display: flex;
      flex-direction: column;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 10px;
      padding-bottom: 10px;
    `

    const orderPropertyContainer = css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span {
        font-size: 14.5px;
      }
    `

    const getStage = () => {
      if (status) {
        switch (status) {
          case "ORDERED":
            return 0
          case "OUT_FOR_DELIVERY":
            return 1
          case "DELIVERED":
            return 3
          case "CANCELLED":
            return 1
        }
      } else {
        return -1
      }
    }

    const stepperPart = () => (
      <>
        <span
          style={{
            marginTop: 25,
            marginBottom: 15,
            fontSize: 17,
            fontWeight: 700,
            fontFamily: "Noto Sans KR",
          }}
        >
          주문진행상황
        </span>
        <Stepper
          activeStep={getStage()}
          style={{
            marginBottom: 10,
            border: "2px solid rgba(0,0,0,0.5)",
            padding: 0,
            paddingTop: 20,
            paddingBottom: 15,
          }}
          alternativeLabel
        >
          <Step key="ORDERED">
            <StepLabel>확인중</StepLabel>
          </Step>
          {status === "CANCELLED" && (
            <Step key="CANCELLED">
              <StepLabel error={true}>주문취소</StepLabel>
            </Step>
          )}
          <Step key="OUT_FOR_DELIVERY">
            <StepLabel>배송중</StepLabel>
          </Step>
          <Step key="DELIVERED">
            <StepLabel>배송완료</StepLabel>
          </Step>
        </Stepper>
      </>
    )

    return (
      <div className={styles["selectedDeliveryContainer"]}>
        {isSelectedDeliveryCancelled(context?.customerHook.deliveries[selectedDeliveryIndex]) ? (
          <div className={"v-box " + styles["cancelledReasonText"]}>
            <span className={styles["cancelledReasonTitleText"]}>취소됨</span>
            <span>
              사유: {context?.customerHook.deliveries[selectedDeliveryIndex].cancelledReason}
            </span>
          </div>
        ) : null}
        <span
          style={{
            marginBottom: 15,
            fontSize: 17,
            fontWeight: 700,
            fontFamily: "Noto Sans KR",
          }}
        >
          주문정보
        </span>
        <div css={orderBasicDataContainer}>
          <div css={orderPropertyContainer}>
            <span>주문일시</span>
            <span>
              {moment(new Date(context?.customerHook.deliveries[selectedDeliveryIndex]?.orderDateTime)).format(
                "YYYY-MM-DD HH:mm:ss"
              )}  
            </span>
          </div>
          <div css={orderPropertyContainer}>
            <span>결제수단</span>
            <span>
              {context?.customerHook.deliveries.length == 0
                ? ``
                : formatPaymentType(
                    context?.customerHook.deliveries[selectedDeliveryIndex].paymentType
                  )}
            </span>
          </div>
          {/* <div css={orderPropertyContainer}>
            <span>소계</span>
            <span>
              {getSum(context?.customerHook.deliveries[selectedDeliveryIndex]).price}
              {CURRENCY_WON}
            </span>
          </div>
          <div css={orderPropertyContainer}>
            <span>할인가</span>
            <span>
              {getSum(context?.customerHook.deliveries[selectedDeliveryIndex]).sale}
              {CURRENCY_WON}
            </span>
          </div> */}
          <div css={orderPropertyContainer}>
            <span>주문금액</span>
            <span>
              {getSum(context?.customerHook.deliveries[selectedDeliveryIndex]).total}
              {CURRENCY_WON}
            </span>
          </div>
          <div css={orderPropertyContainer}>
            <span>포인트 적립 번호</span>
            <span>
              {context?.customerHook.deliveries.length == 0
                ? ``
                : context?.customerHook.deliveries[selectedDeliveryIndex].pointNumber}
            </span>
          </div>
          
        </div>
        <div
          className={
            isSelectedDeliveryCancelled(context?.customerHook.deliveries[selectedDeliveryIndex])
              ? styles["cancelled"]
              : "v-box"
          }
        >
          {/* <div className="h-box-spaced">
            <div className={styles["statusContainer"]} style={{ marginTop: 20, marginBottom: 15 }}>
              {context?.customerHook.deliveries.length == 0
                ? ``
                : getDeliveryStatusText(context?.customerHook.deliveries[selectedDeliveryIndex])}
            </div>
          </div> */}
          {stepperPart()}

          <span
            className={styles["orderNumber"]}
            style={{
              marginBottom: 15,
              color: "rgba(0,0,0,0.8)",
              fontSize: 16,
              fontFamily: "Noto Sans KR",
              fontWeight: 500,
            }}
          >
            {context?.customerHook.deliveries.length == 0
              ? ``
              : (context?.customerHook.deliveries[selectedDeliveryIndex].cancelledReason &&
                  `취소사유: ${context?.customerHook.deliveries[selectedDeliveryIndex].cancelledReason}`) ||
                `메모: ${context?.customerHook.deliveries[selectedDeliveryIndex].note}`}
          </span>
          <span
            style={{
              marginTop: 10,
              marginBottom: 15,
              fontSize: 17,
              fontWeight: 700,
              fontFamily: "Noto Sans KR",
            }}
          >
            주문상품목록
          </span>
          <div className={"v-box " + styles["cartItemContainer"]}>
            {context?.customerHook.deliveries[selectedDeliveryIndex]?.items?.map(item => (
              <CartItemInDelivery cartItem={item} />
            ))}
          </div>
        </div>
        <div className="h-box" style={{ justifyContent: "space-between" }}>
          <ArrowButton
            arrowDirection="LEFT"
            disabled={selectedDeliveryIndex <= 0}
            name="이전 주문내역"
            onClick={() => setSelectedDeliveryIndex(selectedDeliveryIndex - 1)}
          />
          <ArrowButton
            arrowDirection="RIGHT"
            disabled={
              context ? selectedDeliveryIndex >= context.customerHook.deliveries.length - 1 : true
            }
            name="다음 주문내역"
            onClick={() => setSelectedDeliveryIndex(selectedDeliveryIndex + 1)}
          />
        </div>
      </div>
    )
  }

  return (
    <Layout nowPage={PAGE.DELIVERY}>
      <DeliveryContextProvider>
        <deliveryContext.Consumer>
          {context => (
            <div className={styles["container"]}>
              <PageTitleText title={DELIVERY_PAGE_TITLE} style={{paddingLeft: 20}}/>
              <div className={styles["itemContainer"]}>
                {orderedDeliveryListUI(context)}
                {selectedDeliveryUI(context)}
              </div>
            </div>
          )}
        </deliveryContext.Consumer>
      </DeliveryContextProvider>
    </Layout>
  )
}

export default DeliveryPage
